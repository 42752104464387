<template>
  <div class="section-wrapper">
    <!-- <div class="breadcrumb-wrapper">
      <b-breadcrumb class="custom-bread"></b-breadcrumb>
    </div> -->
    <b-breadcrumb>
      <b-breadcrumb-item to="/dashboard">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <!-- <b-breadcrumb-item to="/user">Manage Book</b-breadcrumb-item> -->
      <b-breadcrumb-item active>Coupon</b-breadcrumb-item>
    </b-breadcrumb>
      <div class="form-wrapper">
      <b-card title="Search">
          <b-card-text>
              <b-row style="font-size: 13px;">
                <b-col lg="3" md="3" sm="3" xs="6">
                <b-form-group
                 label-for="subscription_plan_id"
                >
                <template v-slot:label>
                 Subscription Plan
                </template>
                <b-form-select
                    plain
                    v-model="search.subscription_plan_id"
                    :options="subscriptionPlanList"
                    id="subscription_plan_id"
                   >
                  <template v-slot:first>
                   <b-form-select-option :value=null>Select</b-form-select-option>
                 </template>
                </b-form-select>
              </b-form-group>
              </b-col>
                <b-col lg="3" md="3" sm="3" xs="6">
                  <b-form-group
                    id="name"
                    label="name"
                    label-for="name"
                  >
                  <template v-slot:label>
                   Name
                  </template>
                    <b-form-input
                      id="name"
                      v-model="search.name"
                      placeholder="Enter Name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="3" sm="3" xs="6">
                  <b-form-group
                    id="coupon_code"
                    label="coupon_code"
                    label-for="coupon_code"
                  >
                  <template v-slot:label>
                   Coupon Code
                  </template>
                    <b-form-input
                      id="coupon_code"
                      v-model="search.coupon_code"
                      placeholder="Enter Coupon Code"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="3" sm="3" xs="6">
                  <b-form-group
                    id="percentage"
                    label="Percentage"
                    label-for="percentage"
                  >
                  <template v-slot:label>
                    Percentage
                  </template>
                    <b-form-input
                      id="percentage"
                      v-model="search.percentage"
                      placeholder="Enter Percentage"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="3" sm="3" xs="6">
                   <b-form-group
                    label-for="start_date">
                    <template v-slot:label>
                      Start Date
                     </template>
                    <flat-pickr
                    id="start_date"
                    v-model="search.start_date"
                    class="form-control"
                    placeholder="select Date"
                    />
                  </b-form-group>
              </b-col>
                <b-col lg="3" md="3" sm="3" xs="6">
                   <b-form-group
                    label-for="end_date">
                    <template v-slot:label>
                      End Date
                     </template>
                    <flat-pickr
                    id="end_date"
                    v-model="search.end_date"
                    class="form-control"
                    placeholder="select Date"
                    />
                  </b-form-group>
              </b-col>
              <b-col sm="12" md="3">
                  <br>
                  <b-button size="sm" variant="primary" @click="searchData"><i class="ri-search-line"></i> Search</b-button>
                  <b-button size="sm ml-1" variant="danger" @click="clearData"><i class="ri-close-line"></i> Clear</b-button>
                </b-col>
              </b-row>
          </b-card-text>
      </b-card>
  </div>
  <b-card class="mt-3">
    <b-card-title>
      <b-row>
        <b-col>
          <h4 class="card-title mb-0 pl-0">Coupons List</h4>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="has_permission('add_new_coupon')" size="sm" variant="info" @click="openAddNewModal()"><i class="ri-add-fill"></i>Add New Coupon</b-button>
        </b-col>
      </b-row>
    </b-card-title>
      <b-row>
      <b-col>
        <b-overlay :show="loading">
            <div class="table-wrapper table-responsive">
              <table class="table table-striped table-hover table-bordered">
                <thead>
                  <tr style="font-size: 12px;">
                    <th scope="col" class="text-center">SL</th>
                    <th scope="col" class="text-center">Subscription Plan</th>
                    <th scope="col" class="text-center">Name</th>
                    <th scope="col" class="text-center">Coupon Code</th>
                    <th scope="col" class="text-center">Percentage</th>
                    <th scope="col" class="text-center">Start Date</th>
                    <th scope="col" class="text-center">End Date</th>
                    <th scope="col" class="text-center">Active</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                </thead>
                <template v-if="listData.length > 0">
                  <tbody v-for="(item, index) in listData" :key="index">
                    <tr style="font-size: 11px;">
                      <td scope="row" class="text-center">{{ index + pagination.slOffset }}</td>
                      <td class="text-center">{{ getSubscriptionPlanName(item.subscription_plan_id) }}</td>
                      <td class="text-center">{{ item.name }}</td>
                      <td class="text-center">{{ item.coupon_code }}</td>
                      <td class="text-center">{{ item.percentage ?? 0 }}%</td>
                      <td class="text-center">{{ dDate(item.start_date) }}</td>
                      <td class="text-center">{{ dDate(item.end_date) }}</td>
                      <td class="text-center">
                      <b-form-checkbox :disabled="!has_permission('active_or_deactive_coupon')" @change="toggleActiveStatus(item)" v-model="item.active" name="check-button" switch>
                        </b-form-checkbox>
                      </td>
                      <td class="text-center">
                        <a v-tooltip="'Edit'" v-if="has_permission('edit_coupon')" href="javascript:" style="width: 20px !important; height: 20px !important; font-size:10px" class="action-btn edit" @click="editData(item)"><i class="ri-pencil-fill"></i></a>
                        <a v-tooltip="'Delete'" v-if="has_permission('delete_coupon')" @click="deleteConfirmation(item)" style="width: 20px !important; height: 20px !important; font-size:10px" href="javascript:" class="action-btn delete"><i class="ri-delete-bin-2-line"></i></a>
                      </td>
                    </tr>
                  </tbody>
                 </template>
                 <template v-else>
                    <tr>
                       <td colspan="12" class="notFound">Data Not Found</td>
                    </tr>
                 </template>
              </table>
            </div>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
    <b-modal id="modal-1" ref="editModal" size="md" title="Coupon Form" :hide-footer="true">
      <Form @loadList="loadData" :editItem="editItem"/>
    </b-modal>
    <!-- pagination -->
    <div class="pagination-wrapper mt-4">
      <span>Showing {{ pagination.slOffset }} from {{ pagination.totalRows }} entries</span>
      <b-pagination
        size="sm"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        @input="searchData"
        />
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Form from './Form.vue'
import RestApi, { baseURL } from '@/config'
export default {
  components: {
    Form, flatPickr
  },

  data () {
    return {
      // pagination
      rows: 100,
      currentPage: 1,
      // form data
      search: {
        subscription_plan_id: null,
        name: '',
        coupon_code: '',
        percentage: '',
        start_date: '',
        end_date: ''
      },
      value: '',
      listData: [],
      loading: false,
      editItem: '',
      flatPickrConfig: {
        maxDate: 'today',
        dateFormat: 'd-m-Y'
      },
      subscriptionPlanList: []
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.getSubscriptionPlanList()
  },
  computed: {
  },
  methods: {
    openAddNewModal () {
      this.editItem = ''
      this.$refs.editModal.show()
    },
    editData (item) {
      this.editItem = item
      this.$refs.editModal.show()
    },
    searchData () {
      this.loadData()
    },
    clearData () {
      this.search = {
        subscription_plan_id: null,
        name: '',
        coupon_code: '',
        percentage: '',
        start_date: '',
        end_date: ''
      }
      this.loadData()
    },
    async loadData () {
      this.loading = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      var result = await RestApi.getData(baseURL, 'api/v1/admin/ajax/get_coupon_list', params)
      if (result.success) {
        this.listData = result.data.data
        this.paginationData(result.data)
      }
      this.loading = false
    },
    async toggleActiveStatus (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/toggle_coupon_data', item)
      if (result.success) {
        this.$toast.success({ title: 'Success', message: result.message })
        this.loadData()
      }
      this.loading = false
    },
    getSubscriptionPlanList () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_subscription_plan_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.subscriptionPlanList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text }
            })
        } else {
          this.subscriptionPlanList = []
        }
      })
    },
    getSubscriptionPlanName (Id) {
      const obj = this.subscriptionPlanList.find(el => el.value === Id)
      if (obj !== undefined) {
        return obj.text
      } else {
        return ''
      }
    },
    deleteConfirmation (item) {
      this.$swal({
        title: 'Are you sure to delete?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          // declare confirmed method to hit api
          this.deleteRole(item)
        }
      })
    },
    async deleteRole (item) {
      this.loading = true
      var result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/delete_coupon_data', item)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.loadData()
      }
      this.loading = false
    }
  }
}
</script>
<style>
 .notFound {
   text-align: center;
 }
</style>
