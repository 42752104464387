<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Subscription Plan" vid="subscription_plan_id" rules="required|min_value:1">
              <b-form-group
                label-for="subscription_plan_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Subscription Plan <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.subscription_plan_id"
                :options="subscriptionPlanList"
                id="subscription_plan_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                <b-form-select-option :value=null>Select</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Name" vid="name" rules="required" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="12"
                id="name"
                label-for="name"
              >
              <template v-slot:label>
                Name <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="name"
                  v-model="form.name"
                  placeholder="Enter Name"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Coupon Code" vid="coupon_code" rules="required" v-slot="{ errors }">
              <b-form-group
                class="row"
                label-cols-sm="12"
                id="coupon_code"
                label-for="coupon_code"
              >
              <template v-slot:label>
                Coupon Code <span class="text-danger">*</span>
              </template>
                <b-form-input
                  id="coupon_code"
                  v-model="form.coupon_code"
                  placeholder="Enter Coupon Code"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Percentage" vid="percentage" v-slot="{ errors }">
              <b-form-group
                id="percentage"
                label="Vat Percent"
                label-for="percentage"
              >
              <template v-slot:label>
                percentage
              </template>
              <b-input-group append="%">
                <b-form-input
                  id="percentage"
                  v-model="form.percentage"
                  placeholder="Enter Percentage"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                ></b-form-input>
              </b-input-group>
                <div class="d-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="start Date" vid="start_date" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="start_date">
                <template v-slot:label>
                  Start Date <span class="text-danger">*</span>
                </template>
                <flat-pickr
                  id="start_date"
                  v-model="form.start_date"
                  class="form-control"
                  placeholder="select Date"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :config="flatPickrConfig"
                />
                <div class="d-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="End Date" vid="end_date" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="end_date">
                <template v-slot:label>
                  End Date <span class="text-danger">*</span>
                </template>
                <flat-pickr
                  id="end_date"
                  v-model="form.end_date"
                  class="form-control"
                  placeholder="select Date"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :config="EndflatPickrConfig"
                />
                <div class="d-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
        </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import RestApi, { baseURL } from '@/config'
import moment from 'moment'

export default {
  props: ['editItem'],
  components: {
    flatPickr
  },
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        subscription_plan_id: null,
        name: '',
        coupon_code: '',
        percentage: '',
        start_date: '',
        end_date: ''
      },
      errors: [],
      valid: null,
      loading: false,
      subscriptionPlanList: [],
      flatPickrConfig: {
        // maxDate: 'today',
        dateFormat: 'd-m-Y'
      },
      EndflatPickrConfig: {
        dateFormat: 'd-m-Y'
      }
    }
  },
  created () {
    this.getSubscriptionPlanList()
    if (this.editItem) {
      this.form = this.clone_object(this.editItem)
      this.form.start_date = moment(this.form.start_date).format('DD-MM-YYYY')
      this.form.end_date = moment(this.form.end_date).format('DD-MM-YYYY')
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.form.id) {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/update_coupon_data', this.form)
      } else {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/store_coupon_data', this.form)
      }
      this.loading = false
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
      } else {
        // if (result.errors) {
        //   this.$toast.error({
        //     message: result.message
        //   })
        // }
        this.$refs.form.setErrors(result.errors)
      }
    },
    getSubscriptionPlanList () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_subscription_plan_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.subscriptionPlanList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text }
            })
        } else {
          this.subscriptionPlanList = []
        }
      })
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
